import React from 'react';
import classNames from 'classnames';
import dictData from 'dictionary';

interface IProps {
  active: boolean;
  toggleMobileMenu: () => void;
}

const NavigationBurger: React.FC<IProps> = ({ active, toggleMobileMenu }) => {
  return (
    <button aria-label={dictData.navigation.toggleBtn} className="navigation-burger-btn" onClick={toggleMobileMenu}>
      <div className={classNames('navigation-burger', { active: active })}>
        <div className="navigation-burger-line" />
      </div>
    </button>
  );
};

export default NavigationBurger;
