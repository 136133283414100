import React, { PropsWithChildren } from 'react';
import { useMedia } from 'react-use';

interface IMatchMediaContext {
  isXs: boolean;
  isSm: boolean;
  isMd: boolean;
  isLg: boolean;
  isXl: boolean;
}

export const MatchMediaContext = React.createContext<IMatchMediaContext>({
  isXs: false,
  isSm: false,
  isMd: false,
  isLg: false,
  isXl: false
});

export const MatchMediaContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const mediumBS = useMedia('(min-width: 768px)');
  const isXs = useMedia('(max-width: 575.98px)');
  const isLg = useMedia('(min-width: 992px)');
  const isXl = useMedia('(min-width: 1200px)');
  const isMd = mediumBS && !isLg;
  const isSm = !isXs && !isMd && !isLg;

  return (
    <MatchMediaContext.Provider
      value={{
        isXs,
        isSm,
        isMd,
        isLg,
        isXl
      }}
    >
      {children}
    </MatchMediaContext.Provider>
  );
};
