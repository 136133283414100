import axios from 'axios';
import { UseQueryOptions, UseQueryResult, useQuery, QueryFunctionContext } from 'react-query';
import { controllers, IServerErrorObject, parseError } from 'api';
import { ISingleParameters, ITranslations } from 'api/wordpress/baseTypes';

export interface IPagePayload<ACF> {
  id: number;
  date: string;
  slug: string;
  title: string;
  template: string;
  content: string;
  acf: ACF;
  translations?: ITranslations;
}

const getPage = async <ACF>(key: string, params?: ISingleParameters): Promise<IPagePayload<ACF>> => {
  try {
    const payload = await axios.get<IPagePayload<ACF>>(`${controllers.cms.dataController}${key}`, {
      params
    });
    return payload.data;
  } catch (reason) {
    throw parseError(reason);
  }
};

export const usePage = <ACF>(
  slug: string,
  params?: ISingleParameters,
  queryConfig?: UseQueryOptions<IPagePayload<ACF>, IServerErrorObject>
): UseQueryResult<IPagePayload<ACF>, IServerErrorObject> => {
  return useQuery<IPagePayload<ACF>, IServerErrorObject>(
    [`page/${slug}`, params],
    ({ queryKey }: QueryFunctionContext<[string, ISingleParameters | undefined]>): Promise<IPagePayload<ACF>> =>
      getPage<ACF>(queryKey[0], queryKey[1]),
    queryConfig
  );
};
