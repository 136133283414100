import sanitizeHtml from 'sanitize-html';

//todo - config for svg allowedAttributes
export const sanitizer = (text: string): string => {
  const sanitized = sanitizeHtml(text, {
    allowedTags: [
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'blockquote',
      'p',
      'a',
      'ul',
      'ol',
      'nl',
      'li',
      'b',
      'i',
      'strong',
      'em',
      'hr',
      'br',
      'div',
      'table',
      'thead',
      'caption',
      'tbody',
      'tr',
      'th',
      'td',
      'iframe',
      'img',
      'svg',
      'span',
      'sup',
      'sub'
    ],
    allowedAttributes: {
      a: ['href', 'name', 'target', 'rel'],
      div: ['class', 'id'],
      img: ['src', 'srcset', 'data-src', 'data-srcset', 'alt', 'class', 'width', 'height', 'sizes', 'align'],
      span: ['class', 'id'],
      h2: ['class', 'id'],
      iframe: [
        'src',
        'data-src',
        'data-expand',
        'width',
        'height',
        'name',
        'class',
        'allowfullscreen',
        'encrypted-media',
        'picture-in-picture',
        'frameborder',
        'loading',
        'scrolling',
        {
          name: 'allow',
          multiple: true,
          values: [
            'autoplay',
            'autoplay;',
            'fullscreen',
            'fullscreen;',
            'accelerometer',
            'accelerometer;',
            'encrypted-media',
            'encrypted-media;',
            'picture-in-picture',
            'picture-in-picture;',
            'gyroscope',
            'gyroscope;'
          ]
        },
        {
          name: 'sandbox',
          multiple: true,
          values: ['allow-same-origin']
        }
      ]
    },
    // Lots of these won't come up by default because we don't allow them
    selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
    allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
    allowedIframeHostnames: ['www.youtube.com', 'player.vimeo.com', 'anchor.fm'],
    allowProtocolRelative: true
  });
  return sanitized;
};
