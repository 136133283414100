import React from 'react';

const Arrow: React.FC = () => {
  return (
    <svg className="ose-arrow-small" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="arrow" transform="translate(20) rotate(90)">
        <rect id="Rectangle_1331" data-name="Rectangle 1331" width="20" height="20" fill="none" />
        <g id="arrow-point-to-right" transform="translate(15 7.001) rotate(90)">
          <path
            id="Path_771"
            data-name="Path 771"
            fill="currentColor"
            d="M5.5,5.5l-4.3,4.3a.7.7,0,0,1-.99-.99L4.01,5,.205,1.2A.7.7,0,0,1,1.2.205L5.5,4.5a.7.7,0,0,1,0,.99Z"
          />
        </g>
      </g>
    </svg>
  );
};
export default Arrow;
