import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IOSESchoolData } from 'api/services/registration/ose';
import { IMlegSchoolData } from 'api/services/registration/mleg';
import routeList from 'routes/routeList';

export interface IAuthState<T> {
  schoolId: number | null;
  authCode: string | null;
  verified: boolean;
  schoolData: T | null;
}
export interface ICombinedAuthState {
  ose: IAuthState<IOSESchoolData>;
  mleg: IAuthState<IMlegSchoolData>;
}

interface IAuthContext extends ICombinedAuthState {
  setOSEAuthState: (
    schoolId: number | null,
    authCode: string | null,
    verified: boolean,
    schoolData: IOSESchoolData | null
  ) => void;
  setMlegAuthState: (
    schoolId: number | null,
    authCode: string | null,
    verified: boolean,
    schoolData: IMlegSchoolData | null
  ) => void;
}

const initialState: ICombinedAuthState = {
  ose: {
    schoolId: null,
    authCode: null,
    verified: false,
    schoolData: null
  },
  mleg: {
    schoolId: null,
    authCode: null,
    verified: false,
    schoolData: null
  }
};

const initialContext: IAuthContext = {
  ...initialState,
  setOSEAuthState: () => {},
  setMlegAuthState: () => {}
};

export const AuthContext = React.createContext<IAuthContext>(initialContext);

export const AuthContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [authState, setAuthState] = useState<ICombinedAuthState>(initialState);
  const { pathname } = useLocation();

  const setOSEAuthState = useCallback(
    (schoolId: number | null, authCode: string | null, verified: boolean, schoolData: IOSESchoolData | null) => {
      setAuthState((authState) => ({ ...authState, ose: { schoolId, authCode, verified, schoolData } }));
    },
    []
  );

  const setMlegAuthState = useCallback(
    (schoolId: number | null, authCode: string | null, verified: boolean, schoolData: IMlegSchoolData | null) => {
      setAuthState((authState) => ({ ...authState, mleg: { schoolId, authCode, verified, schoolData } }));
    },
    []
  );

  useEffect(() => {
    if (!pathname.includes(routeList.registerOSE) && !pathname.includes(routeList.registerMLEG)) {
      authState.mleg.verified && setMlegAuthState(null, null, false, null);
      authState.ose.verified && setOSEAuthState(null, null, false, null);
    }
  }, [pathname, authState.mleg.verified, authState.ose.verified, setOSEAuthState, setMlegAuthState]);

  return (
    <AuthContext.Provider value={{ ...authState, setOSEAuthState, setMlegAuthState }}>{children}</AuthContext.Provider>
  );
};
