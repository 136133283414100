import React from 'react';
import { styleVariables } from 'styles/styleVariables';

interface IProps {
  style: object;
  props: object;
}

const scrollerStyle = {
  backgroundColor: styleVariables.color_primary,
  borderRadius: '0px'
};

const CustomScroller: React.FC<IProps> = ({ style, ...props }) => (
  <div style={{ ...style, ...scrollerStyle }} {...props} />
);

export default CustomScroller;
