import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Accordion, Card } from 'react-bootstrap';
import { IMenuItem } from 'api/wordpress/menu';
import { useMenu } from 'api/wordpress/menu';
import { MatchMediaContext } from 'context/MatchMediaContext';
import routeList from 'routes/routeList';
import FooterLogos from 'components/layout/FooterLogos';
import FooterCustomToggle from 'components/layout/FooterCustomToggle';
import CustomNavItem from 'components/layout/CustomNavItem';
import SocialMedia from 'components/layout/SocialMedia';
import dictData from 'dictionary';

const Footer: React.FC = () => {
  const query = useMenu('default');
  const [menuItems, setMenuItems] = useState<IMenuItem[]>();
  const { isXl } = useContext(MatchMediaContext);

  useEffect(() => {
    query.data && setMenuItems(query.data.items);
  }, [query.data]);

  return (
    <footer>
      <div className="footer-wrapper">
        <div className="footer-links content-wrapper">
          <Row className="footer-top-section">
            {isXl && (
              <>
                {menuItems?.map(
                  (elem, index) =>
                    elem.classes[0] !== 'topmenu-only' && (
                      <Col key={index} xs="12" lg>
                        <h4>{elem.title}</h4>
                        <ul>
                          {elem.type === 'dropdown' &&
                            elem.children?.map((el, idx) => (
                              <CustomNavItem elem={el} key={idx} className="footer-link" />
                            ))}
                        </ul>
                      </Col>
                    )
                )}
                <Col xs="12" lg>
                  <h4>{dictData.footer.rulesLinksHeader}</h4>
                  <ul>
                    <li>
                      <Link to={routeList.privacy} className="footer-link">
                        {dictData.footer.privacyBtn}
                      </Link>
                    </li>
                    <li>
                      <Link to={routeList.availabilityDeclaration} className="footer-link">
                        {dictData.footer.rulesBtn}
                      </Link>
                    </li>
                  </ul>
                </Col>
              </>
            )}
            <Col xs="12" lg="auto">
              <SocialMedia className="footer-social-media" type="FOOTER" />
            </Col>
          </Row>
          {!isXl && (
            <Accordion className="footer-accordion">
              {menuItems?.map(
                (elem, index) =>
                  elem.classes[0] !== 'topmenu-only' && (
                    <Card key={index}>
                      <FooterCustomToggle eventKey={`footer-${index}`} title={elem.title} />
                      <Accordion.Collapse className="footer-accordion-body" eventKey={`footer-${index}`}>
                        <Card.Body>
                          <ul>
                            {elem.type === 'dropdown' &&
                              elem.children?.map((el, idx) => (
                                <CustomNavItem elem={el} key={idx} className="footer-link" />
                              ))}
                          </ul>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  )
              )}
              <Card>
                <FooterCustomToggle eventKey={`footer-rules`} title={dictData.footer.rulesLinksHeader} />
                <Accordion.Collapse className="footer-accordion-body" eventKey="footer-rules">
                  <Card.Body>
                    <ul>
                      <li>
                        <Link to={routeList.privacy} className="footer-link">
                          {dictData.footer.privacyBtn}
                        </Link>
                      </li>
                      <li>
                        <Link to={routeList.availabilityDeclaration} className="footer-link">
                          {dictData.footer.rulesBtn}
                        </Link>
                      </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          )}
        </div>
        <FooterLogos />
      </div>
    </footer>
  );
};

export default Footer;
