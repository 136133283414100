import React from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { IMenuItem } from 'api/wordpress/menu';

interface IProps {
  elem: IMenuItem;
  insideCollapse?: boolean;
  tabIndex?: number;
  className?: string;
  closeMobileMenu?: () => void;
}

const CustomNavItem: React.FC<IProps> = ({ elem, insideCollapse, tabIndex, className, closeMobileMenu }) => {
  const externalClass = elem.classes[0] || undefined;

  return (
    <li className={classnames('nav-item', externalClass)}>
      {elem.url.includes('http') ? (
        <a
          className={classnames('nav-link', className, { 'nav-link-mobile-dropdown': insideCollapse })}
          href={elem.url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={closeMobileMenu}
          tabIndex={tabIndex}
        >
          {insideCollapse && <div className={classnames('nav-dropdown-icon', externalClass)} />}
          {elem.title}
        </a>
      ) : (
        <NavLink
          className={classnames('nav-link', className, { 'nav-link-mobile-dropdown': insideCollapse })}
          activeClassName="active"
          to={elem.type === 'dynamic' ? `/${elem.url}` : elem.url}
          onClick={closeMobileMenu}
          tabIndex={tabIndex}
        >
          {insideCollapse && <div className={classnames('nav-dropdown-icon', externalClass)} />}
          <span>{elem.title}</span>
        </NavLink>
      )}
    </li>
  );
};

export default CustomNavItem;
