import { AxiosError } from 'axios';

export const controllers = {
  cms: {
    dataController: '/api/cms/'
  },
  customCms: {
    dataController: '/api/custom-cms/'
  },
  services: {
    dataController: '/api/services/',
    schoolsOse: 'registration/ose/schools',
    schoolsMEnergy: 'osemenergy/schools',
    schoolsMEnergyVerify: 'osemenergy/school/verify',
    schoolsMEnergyRegistration: 'osemenergy/registration',
    schoolsMleg: 'registration/mleg/schools',
    schoolRegistrationDataOse: 'registration/ose/school',
    schoolRegistrationDataMleg: 'registration/mleg/school',
    schoolsPkc: 'pkc/schools',
    schoolsPkcVerify: 'pkc/school/verify',
    schoolsPkcRegistration: 'pkc/registration',
    schoolsPkcFile: 'pkc/file',
    contactController: '/api/contact'
  }
};

export interface IServerError {
  readonly code: string;
  readonly message: string;
  readonly details?: string;
}

export interface IServerErrorObject extends Error {
  readonly errors: IServerError[];
}

export const parseError = (reason: AxiosError<IServerErrorObject>): IServerErrorObject => {
  if (Array.isArray(reason.response?.data.errors) && !!reason.response?.data.errors.length) {
    return {
      name: reason.name,
      message: reason.message,
      errors: reason.response?.data.errors
    };
  }
  return {
    name: reason.name,
    message: reason.message,
    errors: []
  };
};
