import { FC } from 'react';
import { Link } from 'react-router-dom';
import routeList from 'routes/routeList';
import accessKeys from 'dictionary/accessKeys';

const AccessKeys: FC = () => {
  return (
    <>
      <Link aria-label={routeList.contact} to={routeList.contact} accessKey={accessKeys.contact} className="d-none" />
      <Link
        aria-label={routeList.availabilityDeclaration}
        to={routeList.availabilityDeclaration}
        accessKey={accessKeys.availabilityDeclaration}
        className="d-none"
      />
      <Link aria-label={routeList.privacy} to={routeList.privacy} accessKey={accessKeys.privacy} className="d-none" />
      <Link aria-label={routeList.home} to={routeList.home} accessKey={accessKeys.home} className="d-none" />
    </>
  );
};

export default AccessKeys;
