import React, { PropsWithChildren, useContext } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { AuthContext, ICombinedAuthState } from 'context/AuthContext';

export interface IPrivateRouteProps {
  path: string;
  exact: boolean;
  redirectPath: string;
  authType: keyof ICombinedAuthState;
}

const PrivateRoute: React.FC<PropsWithChildren<IPrivateRouteProps>> = ({
  children,
  redirectPath,
  authType,
  ...rest
}) => {
  const { ose, mleg } = useContext(AuthContext);
  const { pathname } = useLocation();
  const schoolId = Number(pathname.split('/').pop());

  const isAuthenticated = (authType: keyof ICombinedAuthState): boolean => {
    switch (authType) {
      case 'ose':
        return ose.verified && ose.schoolId === schoolId;
      case 'mleg':
        return mleg.verified && mleg.schoolId === schoolId;
    }
  };

  return <Route {...rest} render={() => (isAuthenticated(authType) ? children : <Redirect to={redirectPath} />)} />;
};

export default PrivateRoute;
