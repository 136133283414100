import React from 'react';
import { ICombinedAuthState } from 'context/AuthContext';
import AsyncLoader from 'components/loading/AsyncLoader';
import routeList from 'routes/routeList';
const HomeLoader = AsyncLoader(() => import('components/pages/home'));
const NewsLoader = AsyncLoader(() => import('components/pages/news'));
const NewsItemLoader = AsyncLoader(() => import('components/pages/newsItem'));
const ContactLoader = AsyncLoader(() => import('components/pages/contact'));
const DynamicLoader = AsyncLoader(() => import('components/pages/dynamic'));
const NotFoundLoader = AsyncLoader(() => import('components/pages/notFound'));
const SchoolListOseLoader = AsyncLoader(() => import('components/pages/schoolListOse'));
const SchoolListMlegLoader = AsyncLoader(() => import('components/pages/schoolListMleg'));
const RegisterOSELoader = AsyncLoader(() => import('components/pages/registerOSE'));
const RegisterMLEGLoader = AsyncLoader(() => import('components/pages/registerMLEG'));
const OseServicesLoader = AsyncLoader(() => import('components/pages/oseServices'));
const OseServicesItemLoader = AsyncLoader(() => import('components/pages/oseServicesItem'));
const OseInternetLoader = AsyncLoader(() => import('components/pages/oseInternet'));
const OseInterneENtLoader = AsyncLoader(() => import('components/pages/oseInternetEN'));
const FaqLoader = AsyncLoader(() => import('components/pages/faq'));
const ForTrsLoader = AsyncLoader(() => import('components/pages/forTrs'));
const RegulationsLoader = AsyncLoader(() => import('components/pages/regulations'));
const EuFundingLoader = AsyncLoader(() => import('components/pages/euFunding'));
const OseCampaignLoader = AsyncLoader(() => import('components/pages/oseCampaign'));
const OseCertificatesLoader = AsyncLoader(() => import('components/pages/oseCertificates'));
const OseCampaignQaLoader = AsyncLoader(() => import('components/pages/oseCampaignQa'));
const ItszkolaRecommendLoader = AsyncLoader(() => import('components/pages/itszkolaRecommend'));
const ForMediaLoader = AsyncLoader(() => import('components/pages/forMedia'));
// const MlegRecommendLoader = AsyncLoader(() => import('components/pages/mlegRecommend'));
const MOchronaRecommendLoader = AsyncLoader(() => import('components/pages/mochronaRecommend'));
/*const MEnergyRecommendLoader = AsyncLoader(() => import('components/pages/menergyRecommend'));*/
const PkcRecommendLoader = AsyncLoader(() => import('components/pages/pkcRecommend'));
// const MlegStudentRecommendLoader = AsyncLoader(() => import('components/pages/mlegStudentRecommend'));
const OseRegulationLoader = AsyncLoader(() => import('components/pages/oseRegulatory'));

export interface IRouteElem {
  readonly path: string;
  readonly component: React.FunctionComponentElement<React.LazyExoticComponent<React.ComponentType<React.FC>>>;
  readonly param?: string;
}

export interface IPrivateRouteElem extends IRouteElem {
  redirectPath: string;
  authType: keyof ICombinedAuthState;
}

export const home: IRouteElem = { path: routeList.home, component: <HomeLoader /> };
export const news: IRouteElem = { path: routeList.news, param: '/:no?', component: <NewsLoader /> };
export const newsItem: IRouteElem = { path: routeList.newsItem, param: '/:slug', component: <NewsItemLoader /> };
export const contact: IRouteElem = {
  path: routeList.contact,
  component: <ContactLoader />
};
export const listOSE: IRouteElem = { path: routeList.listOSE, component: <SchoolListOseLoader /> };
export const listMleg: IRouteElem = { path: routeList.listMleg, component: <SchoolListMlegLoader /> };
export const notFound: IRouteElem = { path: routeList.notFound, component: <NotFoundLoader /> };
export const dynamic: IRouteElem = { path: routeList.dynamic, param: ':slug', component: <DynamicLoader /> };
export const oseServices: IRouteElem = {
  path: routeList.oseServices,
  component: <OseServicesLoader />
};
export const oseServicesItem: IRouteElem = {
  path: routeList.oseServices,
  param: '/:slug',
  component: <OseServicesItemLoader />
};
export const oseInternet: IRouteElem = { path: routeList.oseInternet, component: <OseInternetLoader /> };
export const oseInternetEN: IRouteElem = { path: routeList.oseInternetEN, component: <OseInterneENtLoader /> };
export const faq: IRouteElem = { path: routeList.faq, param: '/:slug?', component: <FaqLoader /> };
export const forTrs: IRouteElem = { path: routeList.forTrs, component: <ForTrsLoader /> };
export const regulations: IRouteElem = { path: routeList.regulations, component: <RegulationsLoader /> };
export const euFunding: IRouteElem = { path: routeList.euFunding, component: <EuFundingLoader /> };
export const oseCampaign: IRouteElem = { path: routeList.oseCampaign, component: <OseCampaignLoader /> };
export const oseCampaignQa: IRouteElem = { path: routeList.oseCampaignQa, component: <OseCampaignQaLoader /> };
export const oseCertificates: IRouteElem = {
  path: routeList.oseCertificates,
  component: <OseCertificatesLoader />
};
export const oseRegulatory: IRouteElem = {
  path: routeList.oseRegulatory,
  component: <OseRegulationLoader />
};
export const forMedia: IRouteElem = { path: routeList.forMedia, component: <ForMediaLoader /> };
export const itszkolaRecommend: IRouteElem = {
  path: routeList.itszkolaRecommend,
  component: <ItszkolaRecommendLoader />
};
// export const mlegRecommend: IRouteElem = { path: routeList.mlegRecommend, component: <MlegRecommendLoader /> };
export const mochronaRecommend: IRouteElem = {
  path: routeList.mochronaRecommend,
  component: <MOchronaRecommendLoader />
};
/*export const menergyRecommend: IRouteElem = {
  path: routeList.menergyRecommend,
  component: <MEnergyRecommendLoader />
};*/

export const pkcRecommend: IRouteElem = {
  path: routeList.pkcRecommend,
  component: <PkcRecommendLoader />
};
// export const mlegStudentRecommend: IRouteElem = {
//   path: routeList.mlegStudentRecommend,
//   component: <MlegStudentRecommendLoader />
// };

export const registerOSE: IPrivateRouteElem = {
  path: routeList.registerOSE,
  param: '/:schoolId',
  component: <RegisterOSELoader />,
  redirectPath: routeList.listOSE,
  authType: 'ose'
};

export const registerMLEG: IPrivateRouteElem = {
  path: routeList.registerMLEG,
  param: '/:schoolId',
  component: <RegisterMLEGLoader />,
  redirectPath: routeList.listMleg,
  authType: 'mleg'
};

const privateRoutes: IPrivateRouteElem[] = [registerOSE, registerMLEG];
const routes: IRouteElem[] = [
  home,
  // mlegStudentRecommend,
  news,
  newsItem,
  oseServicesItem,
  contact,
  listOSE,
  listMleg,
  notFound,
  oseServices,
  oseServicesItem,
  oseInternet,
  oseInternetEN,
  itszkolaRecommend,
  // mlegRecommend,
  mochronaRecommend,
  pkcRecommend,
  forTrs,
  faq,
  regulations,
  euFunding,
  oseCampaign,
  oseCampaignQa,
  oseCertificates,
  oseRegulatory,
  forMedia,
  dynamic
];

export { routes, privateRoutes };
