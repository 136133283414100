import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useCookies } from 'react-cookie';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Modal } from 'react-bootstrap';
import { usePage } from 'api/wordpress/page';
import CustomButton from 'components/shared/CustomButton';
import CustomScroller from 'components/shared/CustomScroller';
import { sanitizer } from 'utils/sanitizer';
import { useBot } from 'hooks/useBot';
import dictData from 'dictionary';

interface IProps {
  cookieName: string;
}

const neverExpire = (accepted: boolean): Date => {
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();
  return accepted ? new Date(year + 1, month, day) : new Date(year, month + 1, day);
};

const CookieModal: React.FC<IProps> = ({ cookieName }) => {
  const [cookies, setCookie] = useCookies([cookieName]);
  const query = usePage(`${dictData.querySlugs.privacy}`, undefined, { enabled: !cookies[cookieName] });
  const isBot = useBot();

  const onAccept = (): void => {
    setCookie(cookieName, 'accepted', {
      path: '/',
      expires: neverExpire(true)
    });
  };

  const onCancel = (): void => {
    setCookie(cookieName, 'declined', {
      path: '/',
      expires: neverExpire(false)
    });
  };

  const handleModalExit = () => {
    const scrollTriggerInstances = ScrollTrigger.getAll();
    if (scrollTriggerInstances.length > 0) {
      ScrollTrigger.refresh(true);
    }
  };

  if (isBot) return null;

  return (
    <>
      <Modal
        className="cookies-modal"
        show={!cookies[cookieName]}
        centered
        backdrop="static"
        onExited={handleModalExit}
      >
        <Modal.Header>
          <h3>{dictData.cookies.modalHeader}</h3>
        </Modal.Header>
        <p>{dictData.cookies.modalIntro}</p>
        <Modal.Body>
          <Scrollbars className="cookies-scrollbar" renderThumbVertical={CustomScroller}>
            {query.isLoading && <p>....</p>}
            {query.data && (
              <div className="cookies-policy" dangerouslySetInnerHTML={{ __html: sanitizer(query.data.content) }} />
            )}
            {query.error && <p>{dictData.cookies.error}</p>}
          </Scrollbars>
        </Modal.Body>
        <p>
          <strong>{dictData.cookies.modalAgreement}</strong>
        </p>
        <p className="cookies-medium">{dictData.cookies.modalQuestion}</p>
        <p>{dictData.cookies.modalOutro}</p>

        <Modal.Footer>
          <CustomButton
            className="cookies-button"
            text={`${dictData.cookies.buttonDeny}`}
            outlined
            onClick={() => onCancel()}
          />
          <CustomButton
            className="cookies-button"
            text={`${dictData.cookies.buttonAccept}`}
            secondary
            onClick={() => onAccept()}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CookieModal;
