import React, { useContext } from 'react';
import AccordionToggle from 'react-bootstrap/AccordionToggle';
import AccordionContext from 'react-bootstrap/AccordionContext';
import classnames from 'classnames';

import Arrow from 'images/icons/Arrow';

interface IProps {
  title: string;
  eventKey: string;
}

const FooterCustomToggle: React.FC<IProps> = ({ title, eventKey }) => {
  const currentEventKey = useContext(AccordionContext);
  return (
    <AccordionToggle
      className={classnames('footer-accordion-toggle', {
        open: currentEventKey === eventKey
      })}
      eventKey={eventKey}
    >
      <h4>{title}</h4>
      <Arrow />
    </AccordionToggle>
  );
};

export default FooterCustomToggle;
