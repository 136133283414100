import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from 'classnames';
import NavigationDropdownItem from 'components/layout/NavigationDropdownItem';
import { usePrevious } from 'hooks/usePrevious';
import { IMenuItemData } from 'api/wordpress/menu';

SwiperCore.use([Navigation]);

interface IProps {
  dropdownItems: IMenuItemData[] | undefined;
  title: string;
  index: number;
  currentActive: number;
  activeDropdown: (index: number) => void;
}

const NavigationDropdown: React.FC<IProps> = ({ activeDropdown, index, currentActive, dropdownItems, title }) => {
  const prevActiveDropdown = usePrevious(currentActive);

  const closeDropdownMenu = () => {
    activeDropdown(0);
    document.body.classList.remove('menu-open');
  };

  const openDropdownMenu = () => {
    if (currentActive === index) {
      activeDropdown(0);
      document.body.classList.remove('menu-open');
    } else {
      activeDropdown(index);
      document.body.classList.add('menu-open');
    }
  };

  return (
    <li className={classNames('nav-item navigation-dropdown')}>
      <button className="nav-link nav-link-btn" onClick={() => openDropdownMenu()}>
        {title}
      </button>
      <div
        className={classNames('navigation-dropdown-element', {
          show: index === currentActive,
          showFast: prevActiveDropdown && prevActiveDropdown !== 0 && currentActive !== 0
        })}
      >
        <Swiper spaceBetween={2} slidesPerView={6} centerInsufficientSlides watchOverflow navigation>
          {dropdownItems &&
            dropdownItems
              .filter((el) => el.classes[0] !== 'bottommenu-only')
              .map((elem, idx) => (
                <SwiperSlide key={idx}>
                  <NavigationDropdownItem
                    elem={elem}
                    closeDropdownMenu={closeDropdownMenu}
                    key={elem.url}
                    tabIndex={index === currentActive ? 0 : -1}
                  />
                </SwiperSlide>
              ))}
        </Swiper>
      </div>
    </li>
  );
};

export default NavigationDropdown;
