import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import CustomButton from 'components/shared/CustomButton';
import ups from 'images/icons/ups.svg';
import dictData from 'dictionary';

const ErrorBoundaryFallback: React.ComponentType<FallbackProps> = ({ resetErrorBoundary }) => {
  return (
    <div className="content-wrapper">
      <div className="error-fallback">
        <img className="error-fallback-img" src={ups} alt="" />
        <h1 className="error-fallback-title">{dictData.errBoundary['fallback-title']}</h1>
        <p className="error-fallback-info">{dictData.errBoundary['fallback-info']}</p>
        <CustomButton text={dictData.errBoundary['fallback-button']} onClick={resetErrorBoundary} link />
      </div>
    </div>
  );
};

export default ErrorBoundaryFallback;
