import React from 'react';

const Rocket: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g id="arrow" transform="translate(0 20) rotate(-90)">
        <rect id="Rectangle_1332" data-name="Rectangle 1332" width="20" height="20" fill="none" />
        <g id="Group_4557" data-name="Group 4557" transform="translate(18.572 1.286) rotate(90)">
          <path
            id="Path_703"
            data-name="Path 703"
            d="M16.636.139,16.6.1A10.911,10.911,0,0,0,7.423,3.41,16.2,16.2,0,0,0,5.2,6.338l-3.289.192L0,8.613,1.144,9.757l2.631-.131c-.176.443-.994,1.949-1.124,2.364l2.3,2.1c.441-.139,1.77-.969,2.241-1.159L7.057,15.67,8.2,16.814,10.283,14.9l.2-3.416a16.1,16.1,0,0,0,2.845-2.171A10.918,10.918,0,0,0,16.636.139ZM11.032,5.7a1.752,1.752,0,1,1,2.478,0A1.753,1.753,0,0,1,11.032,5.7Z"
            transform="translate(1.269)"
          />
          <rect
            id="Rectangle_1312"
            data-name="Rectangle 1312"
            width="1.144"
            height="2.326"
            transform="translate(1.893 11.837) rotate(45)"
          />
          <rect
            id="Rectangle_1313"
            data-name="Rectangle 1313"
            width="1.144"
            height="2.326"
            transform="translate(5.334 15.278) rotate(45)"
          />
          <rect
            id="Rectangle_1314"
            data-name="Rectangle 1314"
            width="1.144"
            height="5.147"
            transform="translate(3.639 13.552) rotate(45)"
          />
        </g>
      </g>
    </svg>
  );
};
export default Rocket;
