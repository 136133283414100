import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { ReactComponent as FacebookIcon } from 'images/icons/facebook-social.svg';
import { ReactComponent as TwitterIcon } from 'images/icons/twitter-social.svg';

import { ReactComponent as FacebookNegativeIcon } from 'images/icons/facebook-negative-social.svg';
import { ReactComponent as TwitterNegativeIcon } from 'images/icons/twitter-negative-social.svg';

import dictData from 'dictionary';
import routeList from 'routes/routeList';

const { links } = dictData;

type TSocialMediaType = 'HEADER' | 'FOOTER';

interface IProps {
  className?: string;
  type: TSocialMediaType;
}

const SocialMedia: React.FC<IProps> = ({ className, type }) => {
  if (type === 'FOOTER') {
    return (
      <div className={classnames('social-media-bar', className)}>
        <div className="content-wrapper-sm0">
          <div className="social-media-bar-container">
            <div className="social-media-icons ">
              <a href={links.facebook} target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                <FacebookNegativeIcon className="social-media-icon" />
              </a>
              <a href={links.twitter} target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                <TwitterNegativeIcon className="social-media-icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classnames('social-media-bar', className)}>
      <div className="content-wrapper-sm0">
        <div className="social-media-bar-container">
          <Link to={routeList.oseInternetEN} className="social-media-bar-link">
            {links.englishTitle}
          </Link>
          <div className="social-media-bar-vertical-line"></div>
          <div className="social-media-icons ">
            <a href={links.facebook} target="_blank" rel="noopener noreferrer" aria-label="Facebook">
              <FacebookIcon className="social-media-icon" />
            </a>
            <a href={links.twitter} target="_blank" rel="noopener noreferrer" aria-label="Twitter">
              <TwitterIcon className="social-media-icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
