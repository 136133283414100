import { Redirect, Route, Switch } from 'react-router-dom';
import { routes, privateRoutes } from 'routes/index';
import routeList from 'routes/routeList';
import PrivateRoute from 'routes/PrivateRoute';

const RoutesProvider = () => (
  <Switch>
    {privateRoutes.map(({ path, param, component, redirectPath, authType }) => (
      <PrivateRoute key={path} exact path={`${path}${param ?? ''}`} redirectPath={redirectPath} authType={authType}>
        {component}
      </PrivateRoute>
    ))}
    {routes.map(({ path, param, component }) => (
      <Route key={path} exact path={`${path}${param ?? ''}`}>
        {component}
      </Route>
    ))}
    <Redirect to={routeList.notFound} exact />
  </Switch>
);

export default RoutesProvider;
