import { FC, useEffect, useRef } from 'react';
import { animateScroll } from 'react-scroll';
import { useIntersection } from 'react-use';
import gsap from 'gsap';

import icon from 'images/icons/arrow_up_btn.svg';
import dictData from 'dictionary';

const ScrollTopFab: FC = () => {
  const intersectionRef = useRef(null);
  const scrollFabRef = useRef(null);
  const showFabTl = useRef<GSAPTimeline>();

  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0
  });

  useEffect(() => {
    showFabTl.current = gsap
      .timeline({ paused: true })
      .to(scrollFabRef.current, { opacity: 1, display: 'block', duration: 0.25, ease: 'linear' });

    return () => {
      showFabTl.current?.kill();
    };
  }, []);

  useEffect(() => {
    if (!intersection?.isIntersecting) {
      showFabTl.current?.play();
    } else {
      showFabTl.current?.reverse();
    }
  }, [intersection?.isIntersecting]);

  return (
    <>
      <div className="fab-inview-tracker" ref={intersectionRef} />
      <div className="fab-scroll-wrapper">
        <div className="content-wrapper d-flex justify-content-end">
          <button
            className="fab-scroll-btn"
            ref={scrollFabRef}
            onClick={() => animateScroll.scrollToTop({ duration: 300, smooth: true })}
          >
            <img src={icon} alt={dictData.scrollTopFab.alt} />
          </button>
        </div>
      </div>
    </>
  );
};

export default ScrollTopFab;
