import React from 'react';
import { useHistory } from 'react-router';
import classnames from 'classnames';
import { IMenuItem } from 'api/wordpress/menu';

interface IProps {
  elem: IMenuItem;
  tabIndex?: number;
  closeDropdownMenu: () => void;
}

const NavigationDropdownItem: React.FC<IProps> = ({ elem, tabIndex, closeDropdownMenu }) => {
  const history = useHistory();
  const iconClass = elem.classes[0] || undefined;

  const redirectToUrl = () => {
    if (elem.url.includes('http')) {
      window.open(elem.url, '_blank');
    } else history.push(elem.type === 'dynamic' ? `/${elem.url}` : elem.url);
    closeDropdownMenu();
  };

  return (
    <div className="nav-dropdown-item">
      <button className="nav-dropdown-btn" onMouseEnter={undefined} onClick={() => redirectToUrl()} tabIndex={tabIndex}>
        <div className={classnames('nav-dropdown-icon', iconClass)} />
        <span>{elem.title}</span>
      </button>
    </div>
  );
};

export default NavigationDropdownItem;
