import axios from 'axios';
import { useQuery, UseQueryResult, QueryFunctionContext } from 'react-query';
import { controllers, IServerErrorObject, parseError } from 'api';

export interface IMenuItemData {
  type: string;
  title: string;
  url: string;
  slug?: string;
  classes: string[];
}

export interface IMenuItem extends IMenuItemData {
  children?: IMenuItemData[];
}

export interface IMenuPayload {
  slug: string;
  items: IMenuItem[];
}

export const getMenu = async (slug: string): Promise<IMenuPayload> => {
  try {
    const payload = await axios.get<IMenuPayload>(`${controllers.cms.dataController}${slug}`);
    return payload.data;
  } catch (reason) {
    throw parseError(reason);
  }
};

export const useMenu = (slug: string): UseQueryResult<IMenuPayload, IServerErrorObject> => {
  return useQuery<IMenuPayload, IServerErrorObject>(
    `menu/${slug}`,
    ({ queryKey }: QueryFunctionContext<[string]>): Promise<IMenuPayload> => getMenu(queryKey[0])
  );
};
