import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import routeList from 'routes/routeList';

const ScrollTopOnPathChange: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.split('/')[1] !== routeList.faq.replace('/', '') ||
      (location.pathname.split('/')[1] === routeList.faq.replace('/', '') && !location.pathname.split('/')[2])
    )
      window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

export default ScrollTopOnPathChange;
