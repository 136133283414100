import React, { useRef, useState, useEffect } from 'react';
import yiq from 'utils/yiq';

interface IProps {
  color?: string;
}

const LoadingSpinner: React.FC<IProps> = ({ color }) => {
  const [strokeColor, setStrokeColor] = useState('#ffffff');
  const spinnerWrapper = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (!spinnerWrapper.current?.parentElement || color) return;

    const spinnerParent = spinnerWrapper.current.parentElement;
    const spinnerParentBackgroundColor = window.getComputedStyle(spinnerParent).backgroundColor;
    const spinnerColor = yiq(spinnerParentBackgroundColor);
    setStrokeColor(spinnerColor);
  }, [color]);

  return (
    <span className="m-0 p-0" ref={spinnerWrapper}>
      <svg
        shapeRendering="geometric-precision"
        className="custom-loading-spinner"
        viewBox="0 0 33 33"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="custom-loading-spinner-path"
          fill="none"
          stroke={color ?? strokeColor}
          strokeWidth="3"
          strokeLinecap="round"
          cx="16.5"
          cy="16.5"
          r="15"
        />
      </svg>
    </span>
  );
};

export default LoadingSpinner;
