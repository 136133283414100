import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import 'lazysizes';
import 'focus-visible';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'styles/styles.scss';

// if (process.env.NODE_ENV !== 'production') {
//   var axe = require('react-axe');
//   axe(React, ReactDOM, 1000);
// }

// const token = document.getElementById('security')?.getAttribute('data-value') || process.env.REACT_APP_CMS_TOKEN;
// axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

ReactDOM.render(<App />, document.getElementById('root'));
