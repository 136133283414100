// has to match with routes translations
const routeList = {
  home: '/',
  news: '/aktualnosci',
  newsItem: '/aktualnosci/wpis',
  contact: '/kontakt',
  registerOSE: '/rejestracja-ose',
  registerMLEG: '/rejestracja-mlegitymacja',
  notFound: '/strona-nie-istnieje',
  listOSE: '/lista-szkol-ose',
  listMleg: '/lista-szkol-mlegitymacja',
  dynamic: '/',
  oseServices: '/internetowe-uslugi-ose',
  faq: '/faq',
  forTrs: '/dla-technicznego-reprezentanta-szkoly',
  regulations: '/regulaminy-i-dokumenty',
  oseInternet: '/internet-ose',
  oseInternetEN: '/en',
  itSzkola: 'https://it-szkola.edu.pl',
  mcMobywatel: 'https://mc.bip.gov.pl/aplikacja-mobywatel/informacje-o-aplikacji-mobywatel.html',
  mojeOse: 'https://moje.ose.gov.pl',
  euFunding: '/dofinansowanie-unijne',
  oseCampaign: '/kampania-ose',
  oseCampaignQa: '/kampania-qa',
  itszkolaRecommend: '/ose-it-szkola',
  // mlegRecommend: '/mlegitymacja',
  mochronaRecommend: '/mochrona',
  menergyRecommend: '/mistrzowie-energii',
  pkcRecommend: '/pkc',
  availabilityDeclaration: '/deklaracja-dostepnosci',
  privacy: '/polityka-prywatnosci',
  forMedia: '/dla-mediow',
  // mlegStudentRecommend: '/mlegitymacja-studencka',
  oseCertificates: '/certyfikaty-ose',
  oseRegulatory: '/regulacje',
  oseHero: 'https://osehero.pl/'
};

export default routeList;
