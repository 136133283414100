import React, { useContext } from 'react';
import { MatchMediaContext } from 'context/MatchMediaContext';
import ose from 'images/icons/logo_ose.svg';
import mc from 'images/icons/mc-logo.svg';
import nask from 'images/icons/logo_nask.svg';
import itszkola from 'images/icons/ose-itszkola.svg';
import mojeose from 'images/icons/moje-ose.svg';
import dictData from 'dictionary';

const FooterLogos: React.FC = () => {
  const { isXl } = useContext(MatchMediaContext);
  return (
    <>
      <div className="footer-bottom-section ose">
        <div className="content-wrapper footer-bottom-content">
          <a className="footer-logo ose" href={dictData.footer.footerLinks.ose.link}>
            <img src={ose} alt={dictData.footer.footerLinks.ose.alt} />
          </a>
          {!isXl && (
            <a
              className="footer-logo"
              href={dictData.footer.footerLinks.nask.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={nask} alt={dictData.footer.footerLinks.nask.alt} />
            </a>
          )}
          <div className="footer-logo-wrapper">
            <a
              className="footer-logo"
              href={dictData.footer.footerLinks.itszkola.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={itszkola} alt={dictData.footer.footerLinks.itszkola.alt} />
            </a>
            <a
              className="footer-logo mojeose"
              href={dictData.footer.footerLinks.mojeose.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={mojeose} alt={dictData.footer.footerLinks.mojeose.alt} />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom-section">
        <div className="content-wrapper footer-bottom-content">
          {isXl && <span>© OSE 2023</span>}
          <div className="footer-logo-wrapper">
            {isXl && (
              <a
                className="footer-logo"
                href={dictData.footer.footerLinks.nask.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={nask} alt={dictData.footer.footerLinks.nask.alt} />
              </a>
            )}

            <a
              className="footer-logo cyber"
              href={dictData.footer.footerLinks.mc.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={mc} alt={dictData.footer.footerLinks.mc.alt} />
            </a>
          </div>
          {!isXl && (
            <div className="w-100 pt-3">
              <span>© OSE 2023</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FooterLogos;
