import React, { Suspense, StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { CookiesProvider } from 'react-cookie';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { getAnalytics } from 'firebase/analytics';

import ErrorBoundaryFallback from 'components/errors/ErrorBoundaryFallback';
import AccessKeys from 'components/layout/AccessKeys';
import GAListener from 'components/analytics/GAListener';
import ScrollTopOnPathChange from 'components/scrollToTop/ScrollTopOnPathChange';
import ScrollTopFab from 'components/scrollToTop/ScrollTopFab';
import CookieModal from 'components/cookies/CookieModal';
import { MatchMediaContextProvider } from 'context/MatchMediaContext';
import RoutesProvider from 'routes/RoutesProvider';
import Loader from 'components/loading/Loader';
import Navigation from 'components/layout/Navigation';
import Footer from 'components/layout/Footer';
import { AuthContextProvider } from 'context/AuthContext';

import { firebaseApp } from 'firebaseConfig';

// check cookie name
const GA_COOKIE = 'GA_COOKIE_OSE2_PUBLIC';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 1
    }
  }
});

const App: React.FC = () => {
  getAnalytics(firebaseApp);
  return (
    <StrictMode>
      <BrowserRouter>
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onReset={() => window.location.reload()}>
          <QueryClientProvider client={queryClient}>
            <CookiesProvider>
              <GAListener cookieName={GA_COOKIE}>
                <CookieModal cookieName={GA_COOKIE} />
                <ScrollTopOnPathChange />
                <ScrollTopFab />
                <MatchMediaContextProvider>
                  <Navigation />
                  <main className="page-container">
                    <Suspense fallback={<Loader />}>
                      <AuthContextProvider>
                        <RoutesProvider />
                      </AuthContextProvider>
                    </Suspense>
                  </main>
                  <Footer />
                  <AccessKeys />
                  <ReactQueryDevtools initialIsOpen={false} />
                </MatchMediaContextProvider>
              </GAListener>
            </CookiesProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </StrictMode>
  );
};

export default App;
