export const useBot = () => {
  const crawlers = [
    'googlebot',
    'Yahoo! Slurp',
    'bingbot',
    'yandex',
    'baiduspider',
    'facebookexternalhit',
    'twitterbot',
    'rogerbot',
    'linkedinbot',
    'embedly',
    'quora link preview',
    'showyoubot',
    'outbrain',
    'pinterest/0.',
    'developers.google.com/+/web/snippet',
    'slackbot',
    'vkShare',
    'W3C_Validator',
    'redditbot',
    'Applebot',
    'WhatsApp',
    'flipboard',
    'tumblr',
    'bitlybot',
    'SkypeUriPreview',
    'nuzzel',
    'Discordbot',
    'Google Page Speed',
    'Qwantify',
    'pinterestbot',
    'Bitrix link preview',
    'XING-contenttabreceiver',
    'Chrome-Lighthouse',
    'Prerender'
  ];
  let foundBot = false;
  foundBot = crawlers.some((element: string) => {
    return navigator.userAgent.toLowerCase().includes(element.toLowerCase());
  });

  return foundBot;
};
