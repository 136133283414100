import React from 'react';
import Collapse from 'react-bootstrap/Collapse';
import CustomNavItem from 'components/layout/CustomNavItem';
import { IMenuItemData } from 'api/wordpress/menu';
import arrow from 'images/icons/navigation-arrow.svg';

interface IProps {
  dropdownItems: IMenuItemData[] | undefined;
  title: string;
  index: number;
  currentActive: number;
  activeDropdown: (index: number) => void;
  closeMobileMenu: () => void;
}

const MobileNavigationDropdown: React.FC<IProps> = ({
  activeDropdown,
  closeMobileMenu,
  index,
  currentActive,
  dropdownItems,
  title
}) => {
  const toggleDropdown = (index: number) => {
    if (index === currentActive) {
      activeDropdown(0);
    } else activeDropdown(index);
  };
  return (
    <div className="nav-item">
      <button
        onClick={() => toggleDropdown(index)}
        className="nav-link nav-link-mobile-arrow"
        aria-controls={`navigation-dropdown-${index}`}
        aria-expanded={currentActive === index}
      >
        {title}
        <img src={arrow} alt="" />
      </button>
      <Collapse in={currentActive === index}>
        <div id={`navigation-dropdown-${index}`}>
          {dropdownItems &&
            dropdownItems.map(
              (elem) =>
                elem.classes[0] !== 'bottommenu-only' && (
                  <CustomNavItem elem={elem} closeMobileMenu={closeMobileMenu} key={elem.url} insideCollapse />
                )
            )}
        </div>
      </Collapse>
    </div>
  );
};

export default MobileNavigationDropdown;
