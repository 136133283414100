import React from 'react';
import classNames from 'classnames';
import _noop from 'lodash/noop';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoadingSpinner from 'components/shared/LoadingSpinner';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  secondary?: boolean;
  outlined?: boolean;
  link?: boolean;
  icon?: React.ReactNode | string;
  iconPlacement?: string;
  className?: string;
  text: string;
  disabled?: boolean;
  to?: string;
  href?: string;
  loading?: boolean;
}

const CustomButton: React.FC<IProps> = ({
  primary,
  secondary,
  outlined,
  link,
  icon,
  iconPlacement,
  className = '',
  text,
  onClick = _noop,
  disabled,
  type = 'button',
  to = '',
  href = undefined,
  loading = false
}) => {
  const handleClick = (e: React.MouseEvent) => onClick(e);

  return (
    <Button
      className={classNames('ose-button', iconPlacement, className, {
        primary: primary,
        secondary: secondary,
        outlined: outlined,
        link: link,
        'with-icon': icon,
        disabled: disabled
      })}
      onClick={handleClick}
      disabled={disabled}
      type={to || href ? undefined : type}
      as={to ? Link : undefined}
      to={to}
      href={href}
      target={href ? '_blank' : undefined}
      rel={href ? 'noopener noreferrer' : undefined}
      variant=""
    >
      {icon && typeof icon === 'string' ? (
        <img className={`ose-button-icon ${iconPlacement}`} src={icon} alt="" />
      ) : (
        <span className={`ose-button-icon ${iconPlacement}`}>{icon}</span>
      )}
      {loading ? <LoadingSpinner /> : `${text}`}
    </Button>
  );
};

export default CustomButton;
