import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { IMenuItem } from 'api/wordpress/menu';
import { useMenu } from 'api/wordpress/menu';
import { MatchMediaContext } from 'context/MatchMediaContext';
import NavigationDropdown from 'components/layout/NavigationDropdown';
import NavigationBurger from 'components/layout/NavigationBurger';
import CustomNavItem from 'components/layout/CustomNavItem';
import SocialMedia from 'components/layout/SocialMedia';
import MobileNavigationDropdown from 'components/layout/MobileNavigationDropdown';

import dictData from 'dictionary';
import routeList from 'routes/routeList';
import logo from 'images/icons/ose-logo.svg';
import Rocket from 'images/icons/Rocket';

const Navigation: React.FC = () => {
  const [menuItems, setMenuItems] = useState<IMenuItem[]>();
  const location = useLocation();
  const [currentDropdownActive, setDropdownActive] = useState<number>(0);
  const [currentMobileDropdownActive, setMobileDropdownActive] = useState<number>(0);
  const [mobileMenuActive, setMobileMenuActive] = useState<boolean>(false);
  const { isXl } = useContext(MatchMediaContext);
  const query = useMenu('default');

  useEffect(() => {
    const menu_items = query.data;
    menu_items && setMenuItems(menu_items.items);
  }, [query.data]);

  const toggleMobileMenu = () => {
    setMobileMenuActive(!mobileMenuActive);
    document.body.classList.toggle('menu-open');
  };

  const closeMobileMenu = () => {
    setMobileMenuActive(false);
    setMobileDropdownActive(0);
    document.body.classList.remove('menu-open');
    setDropdownActive(0);
  };

  useEffect(() => {
    if (isXl) {
      setMobileMenuActive(false);
      setMobileDropdownActive(0);
    } else {
      setDropdownActive(0);
    }
    document.body.classList.remove('menu-open');
  }, [isXl]);

  useEffect(closeMobileMenu, [location.pathname]);

  return (
    <nav className={classnames({ show: currentDropdownActive !== 0 })}>
      {isXl && <SocialMedia type="HEADER" />}
      <div className="navigation content-wrapper-sm0 navigation-content-wrapper">
        {!isXl && <NavigationBurger toggleMobileMenu={toggleMobileMenu} active={mobileMenuActive} />}
        <Link to="/" className="navigation-logo ">
          <img src={logo} alt={dictData.navigation.logoOse} />
        </Link>
        <div className={classnames('navigation-content', { active: mobileMenuActive })}>
          <div className="navigation-links">
            <h3 className="navigation-mobile-header">{dictData.navigation.mobileHeader}</h3>
            {menuItems?.map((elem, index) =>
              elem.type !== 'dropdown' ? (
                <CustomNavItem elem={elem} closeMobileMenu={closeMobileMenu} key={elem.url} />
              ) : isXl ? (
                <NavigationDropdown
                  activeDropdown={setDropdownActive}
                  dropdownItems={elem.children}
                  key={index}
                  title={elem.title}
                  index={index + 1}
                  currentActive={currentDropdownActive}
                />
              ) : (
                <MobileNavigationDropdown
                  activeDropdown={setMobileDropdownActive}
                  closeMobileMenu={closeMobileMenu}
                  dropdownItems={elem.children}
                  key={index}
                  title={elem.title}
                  index={index + 1}
                  currentActive={currentMobileDropdownActive}
                />
              )
            )}
            {!isXl && <SocialMedia type="HEADER" />}
          </div>
        </div>
        <a href={dictData.navigation.myOseLink} target="_blank" rel="noopener noreferrer" className="navigation-btn">
          <Rocket />
          {dictData.navigation.oseBtn}
        </a>
        {!isXl && (
          <Link to={routeList.oseInternetEN} className="navigation-btn-en-link">
            <button className="navigation-btn-en">{dictData.navigation.enBtn}</button>
          </Link>
        )}
      </div>
      <div
        aria-hidden
        className={classnames('navigation-drop', { active: mobileMenuActive || currentDropdownActive })}
        onClick={closeMobileMenu}
      />
    </nav>
  );
};

export default Navigation;
