import { ReactComponent as homeFastIcon } from 'images/icons/home-fast.svg';
import { ReactComponent as homeFreeIcon } from 'images/icons/home-free.svg';
import { ReactComponent as homeSafeIcon } from 'images/icons/home-safe.svg';
import { ReactComponent as noNetworkIcon } from 'images/icons/nonetwork.svg';
import { ReactComponent as MlegFormSubmitIcon } from 'images/icons/register-form-submit-mleg.svg';
import { ReactComponent as OseFormSubmitIcon } from 'images/icons/register-form-submit-ose.svg';

// export type DynamicAccessibleObject = { [key: string]: string };

const dictData = {
  querySlugs: {
    privacy: 'polityka-prywatnosci',
    home: 'strona-glowna',
    oseServices: 'internetowe-uslugi-ose',
    contact: 'kontakt',
    trs: 'dla-technicznego-reprezentanta-szkoly',
    regulations: 'regulaminy-i-dokumenty',
    oseInternet: 'internet-ose',
    oseInternetEN: 'en',
    euFunding: 'dofinansowanie-unijne',
    oseItszkola: 'ose-it-szkola',
    mleg: 'mLegitymacja',
    mlegStudent: 'mlegitymacja-studencka',
    mochrona: 'mochrona',
    menergy: 'mistrzowi-energii',
    pkc: 'pkc',
    forMedia: 'dla-mediow',
    oseCampaign: 'kampania-ose',
    oseCampaignQa: 'kampania-qa',
    oseCertificates: 'certyfikaty-ose',
    oseSecurityServices: 'uslugi-bezpieczenstwa',
    oseRegulating: 'regulacje'
  },
  errBoundary: {
    'fallback-title': 'Coś poszło nie\xa0tak...',
    'fallback-info': 'Podczas wyświetlania strony wystąpił błąd, odśwież stronę lub spróbuj ponownie później.',
    'fallback-button': 'Odśwież stronę'
  },
  errApiLoader: {
    'loader-error-000': 'Przepraszamy, wystąpił błąd.',
    'loader-error-001': 'Prosimy spróbować ponownie załadować stronę www.'
  },
  errApiServices: {
    'register-ose-000': 'Szkoła w tej lokalizacji jest już zarejestrowana w OSE.',
    'register-ose-001': 'Zgoda na rejestrację musi zostać zaakceptowana.',
    'register-ose-002': 'Błędny kod autoryzacyjny.',
    'register-mleg-000': 'Szkoła jest już zarejestrowana w mLegitymacji.',
    'register-mleg-001': 'Zgoda na rejestrację musi zostać zaakceptowana.',
    'register-mleg-002': 'Błędny kod autoryzacyjny.',
    // 'system-000': 'unset',
    // 'system-001': 'not found entity (schoolLocationEntity by id, consent ny id)',
    // 'pagination-000': 'invalid filter operation',
    // 'pagination-001': 'invalid filter field name',
    // 'pagination-002': 'invalid sort ( possible: „asc”/”desc” )',
    // 'validation-000': 'field must be not null',
    // 'validation-001': 'invalid email format',
    // 'validation-002': 'illegal characters ( for @Pattern)',
    // 'validation-003': 'Nieprawidłowy format numeru telefonu',
    // 'validation-004': 'Nieprawidłowy format Pesel',
    // 'validation-005': 'not enough elements or number of elements exceeded (e.g users for mleg)',
    // 'mail-000': 'Błąd wysyłania wiadomości email', - jest obsłużony w oddzielnym procesie na FormSuccessPage
    default: 'Przepraszamy, wystąpił błąd. Prosimy spróbować później lub skontaktować się z administratorem'
  },
  helmet: {
    default_title: 'Ogólnopolska Sieć Edukacyjna',
    default_description: 'Ogólnopolska Sieć Edukacyjna to szybki, bezpieczny i bezpłatny internet w każdej szkole',
    default_img: '/og_img.png',
    home_title: 'Witamy',
    news_title: 'Aktualności',
    faq_title: 'Faq',
    list_title: 'Lista szkół',
    registrationMLEG_title: 'Rejestracja mLegitymacja',
    registrationOSE_title: 'Rejestracja OSE'
  },
  cookies: {
    modalHeader: 'Drogi użytkowniku',
    modalIntro:
      'Przechodząc do serwisu poprzez kliknięcie odnośnika „Akceptuj” wyrażasz zgodę na przetwarzanie przez nas Twoich danych osobowych.',
    modalAgreement: 'Wyrażam zgodę na podstawowe cookies, które są niezbędne aby strona mogła działać prawidłowo.',
    modalQuestion: 'Czy chcesz pozwolić na zbieranie dodatkowo cookies funkcjonalnych?',
    modalOutro:
      'Wykorzystujemy je do analizowania wizyt na stronie internetowej. Pomoże nam to zrozumieć, jak często odwiedzane są poszczególne serwisy oraz pozwoli optymalizować i rozwijać stronę.',
    buttonAccept: 'Akceptuj',
    buttonDeny: 'Blokuj',
    error: 'Wystąpił błąd w pobieraniu danych'
  },
  links: {
    englishTitle: 'in English',
    twitter: 'https://twitter.com/NASK_pl',
    facebook: 'https://www.facebook.com/OSEgovpl',
    youtube: 'https://www.youtube.com/channel/UCyekdMIqy8IuF6hGidUH5yA'
  },
  buttonText: {
    join: 'Przyłącz się',
    readMore: 'Dowiedz się więcej'
  },
  scrollTopFab: {
    alt: 'Powrót na górę strony'
  },
  formValidation: {
    fillAllFields: 'Proszę wypełnić wszystkie pola',
    isRequired: 'Pole wymagane',
    minLength: 'Minimalna liczba znaków: ',
    maxLength: 'Maksymalna liczba znaków: ',
    minValue: 'Minimalna wartość wynosi ',
    mustBeNumber: 'Wartość musi być liczbą ',
    mustBeText: 'Wprowadzono niedozwolone znaki',
    mustBeEmail: 'Wprowadzony adres email jest niepoprawny',
    mustBePesel: 'Niepoprawny numer PESEL',
    mustBePostCode: 'Niepoprawny kod pocztowy',
    mustBeNip: 'Niepoprawny numer NIP. Wprowadź numer bez kresek',
    mustBePhone: 'Proszę wprowadzić 9 cyfr',
    mustBeNumbers: 'Proszę wprowadzić liczbe',
    mustBeLinkWeTransfer: 'Proszę podać link do serwisu WeTransfer',
    mustBeNameSurname: 'Wprowadzono niedozwolone znaki',
    mustBeMobilePhone: 'Proszę wprowadzić prawidłowy numer komórkowy',
    sameEmailsOse: 'Podano różne dane dla tego samego adresu email',
    allFieldsForIod: 'Podaj wszystkie dane inspektora lub wyczyść pozostałe pola w tej sekcji'
  },
  formErrorModal: {
    alt: 'Zdjęcie wystąpił błąd formularza',
    modalTitleError: 'Wystąpił błąd.',
    modalTitleErrors: 'Wystąpiły błędy.'
  },
  dropzoneDict: {
    instruction: 'Przeciągnij i upuść lub kliknij aby dodać plik',
    file: 'Plik',
    failedUpload: 'nie został dodany.',
    'file-too-large': 'Maksymalny rozmiar pliku 15MB.',
    'file-invalid-type': 'Nieodpowiedni format pliku.',
    fileCountLimit: 'Maksymalna liczba załączników:',
    notEnoughFiles: 'Minimalna liczba załączników:'
  },
  header: {
    toggleOpen: 'Otwórz nawigację',
    toggleClose: 'Zamknij nawigację'
  },
  home: {
    bannerTitle: 'Ogólnopolska Sieć Edukacyjna',
    bannerDescription:
      'OSE to program publicznej sieci telekomunikacyjnej, dający szkołom w całej Polsce możliwość podłączenia szybkiego, bezpłatnego i bezpiecznego internetu. Program został zaprojektowany przez Ministerstwo Cyfryzacji, a jego założenia realizuje Państwowy Instytut Badawczy NASK – operator OSE.',
    shortBannerDescription:
      'OSE to program publicznej sieci telekomunikacyjnej, dający szkołom w całej Polsce możliwość podłączenia szybkiego, bezpłatnego i bezpiecznego internetu.',
    internetDeliverySection: {
      title: 'Szybki i bezpieczny internet w szkołach ',
      description:
        'Wyrównywanie szans edukacyjnych wszystkich uczniów w Polsce, rozwój ich kompetencji cyfrowych, wspomaganie procesu kształcenia poprzez ułatwianie dostępu do materiałów i narzędzi znajdujących się w internecie – to tylko niektóre korzyści płynące z podłączenia szkoły do OSE.',
      cta: 'Dowiedz się więcej',
      features: [
        {
          title: 'Szybki',
          description:
            'Internet OSE jest szybki, ponieważ łącze o symetrycznej przepustowości 100 Mb/s jest przeznaczone wyłącznie na potrzeby danej szkoły – nie będzie ono obciążane przez innych użytkowników sieci.',
          animationIcon: homeFastIcon
        },
        {
          title: 'Bezpieczny',
          description:
            'Bezpieczna sieć OSE wykrywa i blokuje szkodliwe oprogramowanie oraz znane wirusy komputerowe, a dodatkowo chroni przed atakami sieciowymi i dostępem do treści nielegalnych lub potencjalnie niebezpiecznych.',
          animationIcon: homeSafeIcon
        },
        {
          title: 'Bezpłatny',
          description:
            'Szybki internet OSE wraz z profesjonalnymi usługami bezpieczeństwa jest finansowany z budżetu państwa. Oznacza to, że szkoły przystępujące do programu otrzymują go bezpłatnie.',
          animationIcon: homeFreeIcon
        }
      ]
    },
    itSzkolaPresentation: {
      subtitle: 'OSE poleca',
      title: 'Portal edukacyjny OSE IT Szkoła',
      description:
        'OSE IT Szkoła to ogólnodostępna, bezpłatna platforma e-learningowa dla uczniów i nauczycieli, na której znajdują się zasoby edukacyjne – w tym ponad 200 kursów – do samodzielnej nauki lub do wykorzystania podczas lekcji.'
    },
    itSzkolaLearn: {
      subtitle: 'OSE poleca',
      title: 'Ucz się z OSE IT Szkołą!',
      description:
        'OSE IT Szkoła to kursy, dzięki którym zdobywanie wiedzy jest przyjemnością. Aby rozpocząć naukę, wystarczy wybrać poziom edukacyjny i kurs w jednej z kilkunastu kategorii tematycznych. Po rozwiązaniu testu końcowego można zdobyć imienny certyfikat, a także zaplanować dalszą ścieżkę edukacyjną.',
      ctaButtonText: 'Przejdź do OSE IT Szkoły'
    },
    mLegPresentation: {
      subtitle: 'Przyłącz się',
      title: 'mLegitymacja szkolna w telefonie',
      description:
        'Każda szkoła, również ta podłączona do internetu OSE, może zgłosić się do projektu mLegitymacja szkolna. Jak uzyskać dostęp do bezpłatnej wersji legitymacji, którą uczniowie będą wyświetlać na ekranach swoich smartfonów? Wystarczą cztery proste kroki.',
      ctaButtonText: 'Zgłoś się do projektu'
    },
    mLegAdvantages: {
      subtitle: 'mLegitymacja',
      title: '8000 szkół korzysta z mLegitymacji!',
      description:
        'mLegitymacja szkolna to usługa realizowana wspólnie przez Ministerstwo Cyfryzacji i NASK. Wygodna i dostępna w smartfonie elektroniczna wersja tradycyjnego dokumentu zyskuje coraz większą popularność wśród nauczycieli, rodziców i uczniów. Jakie są zalety elektronicznej legitymacji?',
      pros: [
        {
          icon: 'MlegPig',
          title: 'Bezpłatna wersja tradycyjnej legitymacji'
        },
        {
          icon: 'MlegHead',
          title: 'Możliwość korzystania z przysługujących uczniom ulg i zniżek'
        },
        {
          icon: 'MlegPhone',
          title: 'Dostęp do dokumentu w smartfonie'
        },
        {
          icon: 'MlegDash',
          title: 'Nowoczesne i bezpieczne narzędzie'
        }
      ]
    },
    homeSectionPresentation: {
      subtitle: 'Ose poleca',
      title: 'OSEhero - projekt edukacyjny dla nauczycieli',
      description:
        'W ramach projektu OSEhero budujemy społeczność nauczycieli szkół podstawowych i ponadpodstawowych, którzy chcą stać się przewodnikami po cyfrowym świecie dla innych – szczególnie dla uczniów. Edukujemy, inspirujemy i zachęcamy do działania na rzecz bezpieczeństwa w sieci. To największy tego typu projekt dla nauczycieli w Polsce.',
      ctaButtonText: 'Więcej informacji'
    },
    homeSectionAdvantages: {
      subtitle: 'Ose poleca',
      title: 'Dlaczego warto dołączyć do OSEhero?',
      description:
        'Uczestnictwo w projekcie OSEhero to niepowtarzalna szansa m.in. na udział w bezpłatnych szkoleniach i spotkaniach z najlepszymi w kraju ekspertami z zakresu bezpieczeństwa w sieci.',
      pros: [
        {
          icon: 'HeroCourses',
          title: `<span>Dostęp do wiedzy i narzędzi (<b>bezpłatnych materiałów edukacyjnych, scenariuszy lekcji, poradników oraz kursów e- learningowych</b>)</span>`
        },
        {
          icon: 'HeroTraining',
          title: `<b>Bezpłatne szkolenia</b> z najlepszymi ekspertami oraz spotkania „przy kawie”`
        },
        {
          icon: 'HeroExp',
          title: `Możliwość <b>dzielenia się doświadczeniami</b> z innymi nauczycielami z całej Polski`
        },
        {
          icon: 'HeroCertificate',
          title: `<b>Certyfikat i tytuł OSEhero</b> ważny przez kolejne dwa lata szkolne – przyznajemy je po spełnieniu warunków projektu`
        },
        {
          icon: 'HeroRally',
          title: `Możliwość udziału w corocznym <b>Zlocie OSEhero i zamkniętej grupie dyskusyjnej</b> - platformie wymiany wiedzy oraz komunikacji w ramach społeczności OSEhero`
        }
      ]
    },
    myOse: {
      title: 'Proste zarządzanie usługami',
      description:
        'Portal Moje OSE to proste narzędzie dla dyrektorów szkół do samodzielnego zarządzania kontem placówki. Można tutaj sprawdzić dane szkoły i status usług dodatkowych, znaleźć niezbędne dokumenty, a także zgłosić problemy techniczne z działaniem sieci.',
      ctaButtonText: 'Zaloguj się do Moje OSE'
    }
  },
  schoolList: {
    additionalFilters: [
      {
        Header: 'Powiat',
        accessor: 'district'
      },
      {
        Header: 'Gmina',
        accessor: 'commune'
      },
      {
        Header: 'Miejscowość',
        accessor: 'city'
      },
      {
        Header: 'Adres',
        accessor: 'address'
      },
      {
        Header: 'Wybierz etap edukacji',
        accessor: 'groupId'
      }
    ],
    placeHolders: {
      rspo: 'RSPO',
      schoolName: 'Wpisz nazwę szkoły',
      voivodeship: 'Województwo',
      authorization: 'Kod autoryzacyjny',
      verify: 'Weryfikuj',
      reportSchool: 'Zgłoś szkołę'
    },
    info: {
      verifyCode: 'Wprowadź otrzymany kod autoryzacyjny *',
      rspoTooltip: 'Proszę wprowadzić liczbę',
      postCodeTooltip: 'Proszę wprowadzić kod pocztowy'
    },
    title: 'Wybierz szkołę do której należysz',
    emptySearch: 'Brak wyników wyszukiwania',
    reportSchool: {
      title: 'Lista uczelni z mLegitymacją',
      fileName: 'OSE_mLegitymacja_studencka_lista'
    },
    downloadBtnText: 'Pobierz'
  },
  schoolListOse: {
    headerTitle: 'Przyłącz się do OSE',
    headerDescription:
      'Witamy na stronie, na której możesz zgłosić szkołę do OSE. W celu zgłoszenia szkoły prosimy o wyszukanie szkoły i naciśnięcie przycisku „Zgłoś szkołę".',
    placeHolders: {
      oseStatus: 'Status OSE'
    },
    info: {
      verifyDetails:
        '* Szkoła otrzymuje unikalny kod autoryzacyjny w wiadomości zapraszającej do Ogólnopolskiej Sieci Edukacyjnej wysyłanej \n na adres e-mail szkoły z bazy SIO. Zaproszenie jest wysyłane z chwilą opublikowania harmonogramu OSE. W przypadku \n braku wiadomości e-mail, zawierającej kod autoryzacyjny prosimy o kontakt z adresu mailowego szkoły na: ose@nask.pl w \n tytule podając numer RSPO szkoły lub dzwoniąc do Centrum Kontaktu OSE tel. 22 182 55 55, wybierając temat rozmowy: kod autoryzacyjny.'
    }
  },
  schoolListMLeg: {
    unregistered: 'Niezgłoszona',
    headerTitle: 'Zgłoś szkołę do mLegitymacji',
    headerDescription: 'Upraszczamy wnioskowanie o możliwość wydawania mobilnych legitymacji szkolnych',

    placeHolders: {
      mLegStatus: 'Status mLegitymacji'
    },
    textOne:
      'Już można składać wniosek o legitymację szkolną – jest on dostępny w Biuletynie Informacji Publicznej Ministerstwa Cyfryzacji. ',
    textOneSecond: 'Po pozytywnym rozpatrzeniu wniosku, szkoła dostanie odpowiednie uprawnienia.',
    textTwo:
      'Jednocześnie trwają prace nad uruchomieniem wydawania legitymacji mobilnej za pośrednictwem Systemu Informacji Oświatowej.',
    textTwoButton: 'Przejdź do BIP Ministerstwa Cyfryzacji',
    info: {
      verifyDetails: '* TBA.'
    }
  },
  registerMLEG: {
    header: {
      title: 'mLegitymacja'
    },
    schoolName: {
      header: 'Dane szkoły do wystawienia mLegitymacji',
      title: 'Nazwa szkoły wyświetlana w Legitymacji',
      formLabels: {
        name: 'Nazwa szkoły'
      }
    },
    personel: {
      header: 'Osoby kontaktowe',
      subtitle: 'Dyrektor szkoły i osoby obsługujące system muszą posiadać Profil Zaufany.',
      headmaster: {
        title: 'Dane Dyrektora',
        formLabels: {
          name: 'Imię',
          surname: 'Nazwisko',
          email: 'E-mail',
          phone: 'Numer telefonu'
        }
      },
      maintainers: {
        title: 'Osoby do obsługi mLegitymacji',
        subtitle: 'Obsługą systemu mLegitymacji może zajmować się również dyrektor szkoły.',
        addMaintainer: 'Dodaj osobę',
        maintainerBox: 'Osoba',
        ariaLabelDelete: 'Usuń',
        formLabels: {
          name: 'Imię',
          surname: 'Nazwisko',
          pesel: 'PESEL'
        }
      },
      iodo: {
        title: 'Dane inspektora ochrony danych osobowych (jeśli został ustanowiony)',
        formLabels: {
          name: 'Imię',
          surname: 'Nazwisko',
          email: 'E-mail',
          phone: 'Numer telefonu'
        }
      }
    },
    attachments: {
      title: 'Załączniki',
      subtitle: `Do rejestracji w Programie mLegitymacja szkolna wymagane jest załączenie następujących plików: Akt powołania na stanowisko Dyrektora oraz `,
      constraints: `Maksymalny rozmiar pliku: 5MB. Dozwolone formaty: .doc, .docx, .xls, .xlsx, .odt, .jpg, .jpeg, .png, .bmp, .pdf,
      .txt, .zip, .7z, .rar`,
      authorization: 'Pełnomocnictwo',
      authorizationFileName: 'Pelnomocnictwo-mlegitymacja.pdf',
      saveFileAriaLabel: 'Pobierz plik'
    },
    successIcon: MlegFormSubmitIcon,
    buttons: {
      prev: 'Wstecz',
      next: 'Dalej',
      submit: 'Zgłoś szkołę'
    }
  },
  registerOSE: {
    header: {
      title: 'Program OSE'
    },
    personnel: {
      header: 'Osoby kontaktowe',
      subtitle:
        'W formularzu wskaż osoby niezbędne do efektywnego funkcjonowania OSE. Jedna osoba może pełnić kilka funkcji.',
      consentText: `Zgłaszam zamiar zawarcia przez Szkołę umowy w celu korzystania z usług operatora OSE, zgodnie z 
        przepisami ustawy o Ogólnopolskiej Sieci Edukacyjnej i tym samym wnioskuję o wprowadzenie do Załącznika 
        nr 5 do umowy ww. danych osobowych, jako danych kontaktowych Abonenta, niezbędnych do zawarcia umowy, 
        a następnie jej realizacji. Wszystkie wskazane powyżej dane osobowe przetwarzam w oparciu o odpowiednią 
        podstawę prawną i jestem uprawniony do ich przekazania operatorowi OSE, dla celów wskazanych powyżej. 
        W razie zmiany tych danych lub konieczności ich zastąpienia danymi innej osoby poinformuję o tym 
        niezwłocznie operatora OSE, wskazując dane aktualne.`,
      info: `Proces w portalu Moje OSE przewiduje dokumentową formę zawarcia umowy. Jeżeli nie wyrażasz zgody 
      na zawarcie umowy w portalu Moje OSE, zaakceptuj formularz i skontaktuj się z nami pod adresem umowaose@nask.pl`,
      roles: {
        headmaster: {
          title: 'Dyrektor szkoły',
          subtitle: `Dyrektor to osoba kluczowa dla OSE. Za zgodą Organu prowadzącego Szkołę decyduje o przystąpieniu 
          Szkoły do programu. Pamiętaj aby podać unikalny adres email Dyrektora szkoły– na adres ten, Dyrektor otrzyma 
          link do założenia konta w portalu Moje OSE gdzie będzie mógł zawrzeć umowę z OSE.`
        },
        technicalAdmin: {
          title: 'Techniczny reprezentant szkoły (TRS)',
          subtitle: `Osoba lub podmiot upoważniony do kontaktów z operatorem OSE w sprawach technicznych.
          Pamiętaj aby podać unikalny adres email TRS szkoły– na adres ten TRS otrzyma link do założenia konta w 
          portalu Moje OSE, gdzie będzie dla niego dostępna do wypełnienia Ankieta Techniczna, której przesłanie jest 
          warunkiem zawarcia przez Szkołę umowy z OSE.`
        },
        oseCoordinator: {
          title: 'Koordynator OSE',
          subtitle: `Koordynator OSE to osoba wskazana do informowania o usługach dostępnych w OSE, upoważniona do włączania 
          i wyłączania usług OSE oraz ich konfiguracji.`
        },
        protocolSigner: {
          title: 'Osoba podpisująca protokół zdawczo-odbiorczy',
          subtitle: 'Osoba wyznaczona przez dyrektora szkoły do podpisania protokołu zdawczo-odbiorczego (opcjonalnie).'
        }
      },
      useMobilePhone: 'Prosimy o podanie numeru telefonu komórkowego',
      contact: 'Dane kontaktowe',
      functions: 'Przypisz dodatkowe funkcje',
      inputLabels: {
        name: 'Imię',
        surname: 'Nazwisko',
        email: 'E-mail',
        phone: 'Numer telefonu'
      },
      checkboxLabels: {
        headmaster: 'Dyrektor szkoły',
        technicalAdmin: 'Techniczny reprezentant szkoły (TRS)',
        oseCoordinator: 'Koordynator OSE',
        protocolSigner: 'Osoba podpisująca protokół zdawczo-odbiorczy'
      },
      accordionAriaLabel: 'Pokaż szczegóły'
    },
    dictServices: {
      header: 'Usługi',
      additionalTransfer: {
        title: 'Dodatkowa transmisja danych',
        description:
          'Możesz zamówić dodatkową, symetryczną transmisję danych powyżej bezpłatnych 100 Mb/s o wielokrotności 50 Mb/s w ramach płatnej opcji.',
        freeInternetCheckbox:
          'Moja szkoła jest zainteresowana bezpłatnym internetem o symetrycznej przepustowości 100 Mb/s',
        additionalInternetCheckbox:
          'Moja szkoła jest zainteresowana dodatkową, płatną transmisją danych o symetrycznej przepustowości powyżej 100 Mb/s',
        additionalInternetTooltip: {
          title: 'Dodatkowa transmisja danych',
          content: `Istnieje możliwość zamówienia dodatkowej symetrycznej transmisji danych powyżej 
          przepustowości 100 Mb/s o wielokrotności 50 Mb/s. Cena za każde 50 Mb/s została Przekazana w piśmie 
          do OPS oraz mailem do OPS i szkoły.`
        },
        chooseSpeedTitle: 'Wybierz prędkość internetu',
        chooseSpeedLabel: 'Prędkość internetu',
        payerTitle: 'Dane Nabywcy do faktury',
        payerDesc: 'Nabywca - np. Jednostka Samorządu Terytorialnego będąca Organem prowadzącym Szkołę.',
        payerNameLabel: 'Nazwa nabywcy',
        payerAddressCheckbox: 'Adres do wysyłki faktury jest inny niż powyżej',
        corespondenceTitle: 'Adres do wysyłki faktury',
        corespondenceNameLabel: 'Nazwa do wysyłki faktury',
        recipientTitle: 'Nazwa odbiorcy',
        recipientNameLabel: 'Nazwa odbiorcy',
        electronicInvoiceCheckbox:
          'Wyrażam zgodę na przesyłanie faktur, duplikatów tych faktur oraz ich korekt, w formie elektronicznej przez NASK na email',
        paperInvoiceCheckbox:
          'Zobowiązuję się przyjmować faktury w formie papierowej w przypadku gdy przeszkody techniczne lub formalne uniemożliwiają przesłanie faktur drogą elektroniczną.'
      },
      malwareProtection: {
        title: 'Ochrona przed szkodliwym oprogramowaniem',
        description:
          'Możesz zdecydować, czy chcesz skorzystać z bezpłatnych usług bezpieczeństwa. Więcej informacji o usługach znajdziesz na stronie ',
        descriptionLink: 'Usługi OSE',
        malwareProtectionCheckbox:
          'Tak, chcę korzystać z bezpłatnych usług bezpieczeństwa ochrony przed szkodliwym oprogramowaniem.',
        contentFiltrationCheckbox:
          'Tak, chcę korzystać z bezpłatnych usług bezpieczeństwa wspierających szkołę w zabezpieczeniu uczniów przed dostępem do treści, które mogą stanowić zagrożenie dla ich prawidłowego rozwoju.',
        documentaryFormOfTheStatement:
          'Oświadczam, że rezygnuję z usługi szerokopasmowego dostępu do Internetu świadczonej na moją rzecz przez ###, który jest stroną Umowy z OSE, i proszę o rozwiązanie zawartej z ###, Umowy w zakresie świadczenia przez ### usługi dostępu do Internetu, przy równoczesnym pozostawieniu na niezmiennych warunkach innych usług świadczonych w oparciu o Umowę,  w związku z moją zgodą na świadczenie usług szerokopasmowego dostępu do Internetu przez NASK-PIB na mocy Ustawy z dnia 27 października 2017 r o Ogólnopolskiej Sieci Edukacyjnej (Dz. U. 2017, poz. 2184), z zachowaniem terminu wynikającego z rozpoczęcia przez OSE świadczenia usługi dostępu do sieci Internet.',
        documentaryFormOfTheStatementTooltip: {
          title: `Dokumentowa forma oświadczenia`,
          content: `W przypadku gdy w momencie składania oświadczenia usługę szerokopasmowego 
          dostępu do Internetu świadczy inny operator niż operator docelowy, z którym OSE 
          ma podpisaną umowę, rezygnacja z usługi powinna zostać złożona do operatora, który 
          świadczy szkole usługi telekomunikacyjne, w terminie wybranym przez Abonenta.`
        }
      },
      internetProvider: {
        title: 'Nazwa operatora, który obecnie świadczy usługi dostępu do internetu lub transmisji danych.',
        chooseProviderLabel: 'Wybierz operatora',
        otherProviderLabel: 'Nazwa obecnego operatora'
      }
    },
    successIcon: OseFormSubmitIcon
  },
  registerShared: {
    schoolData: {
      header: 'Dane szkoły',
      titleOne: 'Nazwa szkoły',
      titleTwo: 'Adres szkoły',
      checkboxLabel: 'Dane mojej szkoły są niepełne lub niepoprawne'
    },
    dictFormLabels: {
      name: 'Nazwa szkoły',
      street: 'Ulica',
      city: 'Miejscowość',
      district: 'Powiat',
      email: 'E-mail',
      schoolEmail: 'E-mail szkoły',
      buildingNum: 'Numer budynku',
      apartmentNum: 'Numer lokalu',
      postalCode: 'Kod pocztowy',
      dictVoivodeship: 'Województwo',
      commune: 'Gmina',
      phone: 'Numer telefonu szkoły',
      nip: 'NIP'
    },
    successPage: {
      header: 'Dziękujemy za zgłoszenie',
      subtitle: 'Informacje o kolejnych krokach prześlemy na adres email podany w formularzu zgłoszeniowym.',
      linkHome: 'przejdź do strony głównej',
      headerError: 'Zgłoś się do Centrum Kontaktu',
      subtitleOseError:
        'Wystąpił błąd w procesie rejestracji w OSE, nie udało się wysłać wiadomości na podany w zgłoszeniu adres email, co jest niezbędne do dalszego procesowania zgłoszenia.',
      subtitleErrorContact1: 'Skontaktuj się z ',
      subtitleErrorLink1: 'Centrum Kontaktu',
      linkContact: 'Przejdź do strony Kontakt'
    },
    checkboxTooltipAriaLabel: 'Czytaj więcej'
  },
  footer: {
    privacyBtn: 'Polityka prywatności',
    rulesBtn: 'Deklaracja dostępności',
    rulesLinksHeader: 'Zasady',
    footerLinks: {
      ose: {
        link: '/',
        alt: 'Ogólnopolska Sieć Edukacyjna Logo'
      },
      mc: {
        link: 'https://www.gov.pl/web/cyfryzacja',
        alt: 'GOV.pl Logo'
      },
      kprm: {
        link: 'https://www.gov.pl/web/premier',
        alt: 'KPRM Logo'
      },
      itszkola: {
        link: 'https://it-szkola.edu.pl/',
        alt: 'Ose It-szkoła Logo'
      },
      nask: {
        link: 'https://www.nask.pl/',
        alt: 'Nask Logo'
      },
      mojeose: {
        link: 'https://moje.ose.gov.pl/',
        alt: 'MojeOSE Logo'
      }
    }
  },
  faq: {
    title: 'Pytania i odpowiedzi'
  },
  notfound: {
    title: 'Strona, której szukasz nie istnieje lub została usunięta.',
    notfoundLink: 'Przejdź do strony głównej.'
  },
  news: {
    bannerTitle: 'Aktualności',
    newsListTitle: 'Artykuły',
    readMore: 'czytaj dalej...',
    nextNews: 'Następny artykuł',
    previousNews: 'Poprzedni artykuł',
    latest: 'Najnowsze artykuły',
    share: 'Udostępnij'
  },
  contactForm: {
    title: 'Napisz do nas',
    labels: {
      name: 'Imię',
      surname: 'Nazwisko',
      email: 'E-mail *',
      phone: 'Numer telefonu',
      subject: 'Temat *',
      message: 'Wiadomość *'
    },
    submitText: 'Wyślij',
    requiredInfo: '* Pole obowiązkowe',
    submitSuccess: 'Wiadomość została wysłana.'
  },
  noContent: 'Brak treści do wyświetlenia',
  consentAccept: 'Zgoda na przetwarzanie danych (wymagane)',
  noNetwork: {
    modalTitle: 'Nie masz połączenia z siecią.',
    modalContent:
      'Odświeżenie strony spowoduje przerwanie sesji. Formularz należy wypełnić ponownie używając tego samego kodu autoryzacyjnego.',
    animationIcon: noNetworkIcon
  },
  ariaLabelClose: 'Zamknij',
  navigation: {
    toggleBtn: 'Otwórz menu',
    mobileHeader: 'Menu',
    oseBtn: 'Moje OSE',
    logoOse: 'logo OSE',
    myOseLink: 'https://moje.ose.gov.pl/',
    enBtn: 'EN'
  },
  forMedia: {
    secondSection: 'Pliki do pobrania'
  },
  oseInternet: {
    topHeaderTitle: 'Internet OSE',
    topHeaderBtn: 'Przyłącz się',
    sectionThreeDecor: 'Internet',
    sectionFive: {
      cardTitleFirstPart: 'Darmowy dostęp',
      cardTitleSecondPart: 'w Twojej szkole',
      cardSubtitle: '0 zł',
      cardItems: [
        { title: 'Prędkość połączenia', value: '100 Mb/s' },
        { title: 'Symetryczny, szerokopasmowy dostęp do internetu' },
        { title: 'Ochrona przed szkodliwym oprogramowaniem' },
        { title: 'Ochrona przed atakami sieciowymi' },
        { title: 'Ochrona przed dostępem' }
      ]
    },
    sectionSixDecor: 'KORZYŚCI'
  },
  oseCampaign: {
    topHeaderTitle: 'Kampania OSE',
    topHeaderBtn: 'Sprawdź',
    thirdSectionBtn: 'Obejrzyj'
  },
  itszkolaRecommend: {
    topHeaderTitle: 'OSE IT Szkoła',
    topHeaderBtn: 'Przyłącz się',
    fifthSectionPretitle: 'OSE Poleca',
    ctaBtnText: 'Przejdź do OSE IT Szkoły'
  },
  mlegRecommend: {
    topHeaderTitle: 'mLegitymacja',
    topHeaderBtn: 'Przyłącz się'
  },
  mlegStudentRecommend: {
    topHeaderTitle: 'mLegitymacja studencka',
    topHeaderBtn: 'Przyłącz się',
    btnLink: 'https://www.gov.pl/web/mobywatel/mlegitymacja-studencka',
    tableSearchByNameFilter: 'Wpisz nazwę uczelni',
    tableError: 'Wystąpił błąd wczytywania danych tabeli.',
    tableErrorLink: 'Odśwież stronę',
    tableFiltersTitle: 'Lista przyłączonych uczelni'
  },
  mochronaRecommend: {
    topHeaderTitle: 'mOchrona',
    topHeaderBtn: 'Pobierz',
    ctaBtnText: 'Dowiedz się więcej',
    ctaBtnTextFifthSection: 'Zaloguj się'
  },
  menergyRecommend: {
    topHeaderTitle: 'Konkurs dla szkół',
    topHeaderBtn: 'Sprawdź wyniki',
    ctaBtnText: 'Więcej informacji',
    ctaBtnTextFifthSection: 'Zaloguj się',
    successTitle: 'Nabór zgłoszeń do konkursu „Mistrzowie Energii” został zakończony.',
    successDesc: 'Dziękujemy za zaangażowanie!'
  },

  menergyFormStepTwo: {
    namePlace: '*  Imię i nazwisko',
    emailPlace: '* E-mail',
    phonePlace: '*  Telefon kontaktowy',
    functionInSchoolPlace: '*  Funkcja w szkole',
    functionInSchoolPlaceNotes: 'Jaka?',
    schoolSubjectPlace: 'Jakiego przedmiotu ?',
    btnText: 'Przejdź dalej'
  },
  menergyFormStepThree: {
    titlePlace: '*  Tytuł/nazwa inicjatywy:',
    childCountPlace: '*  Liczba uczniów w szkole:',
    childCountInInitiativesPlace: '*  Liczba zaangażowanych uczniów:',
    presentPlace: '% zaangażowanych uczniów',
    descriptionPlace: '* Opis inicjatywy:',
    imageLinkPlace: '*  Link do zdjęć',
    infoImageTxt:
      'Link do zdjęć powinien być wygenerowany w serwisie Wetransfer.com. Może zawierać do 10 zdjęć o łącznym rozmiarze do 50 MB.',
    infoVideoTxt: 'Link do wideo powienien być wygenerowany w serwisie Youtube.com',
    infoDescriptionTxt:
      'Opis inicjatywy (do 3000 znaków ze spacjami) – powinien zawierać informacje o tym:\n' +
      '\n- W jaki sposób zrealizowała cele konkursu?\n- Na czym polegała?\n- Jaki był jej przebieg?\n- Kiedy się odbyła?',
    videoLinkPlace: 'Link do wideo',
    terms: '* Oświadczam, że zapoznałem/am się z Regulaminem Konkursu.',
    rodo: '* Oświadczam, że zapoznałem/am się z informacjami RODO.',
    permissions:
      '* Oświadczam, że jestem dyrektorem szkoły lub wyznaczoną przez dyrektora osobą uprawnioną do wysłania zgłoszenia konkursowego.',
    btnText: 'Wyślij zgłoszenie',
    requaredConsent: 'Wymagana zgoda'
  },
  pkcRecommend: {
    titleFirstPkc: 'WAŻNE: Każda szkoła może wysłać tylko jedno zgłoszenie dla danego etapu edukacyjnego.',
    titleSecondPkc: 'Jeśli Twojej szkoły nie ma w bazie, napisz do nas na adres:',
    titleEmailPkc: 'pkc@nask.pl',
    chooseSchool: 'Wybór szkoły',
    competitionEntry: 'Zgłoszenie konkursowe',
    report: 'Dane zgłaszającego',
    studentsStage: 'Liczba uczniów na danym etapie edukacyjnym w szkole:',
    studentsELearning: 'Liczba uczniów, które zrealizowały kurs e-learningowy:',
    studentsPercentInvolved: '% zaangażowanych uczniów',
    competitionContent: 'Praca konkursowa:',
    fillForm: 'Sprawdź wyniki',
    seeMore: 'Zobacz więcej',
    titlePart1: 'Wygrajcie',
    titlePart2: 'pracownie komputerowe',
    titlePart3: 'dla szkół',
    digitalCompetences: 'Pracownia Kompetencji Cyfrowych',
    selected: 'Wybrano',
    choose: 'Wybierz',
    chooseAlt: 'Ikona informująca o wybranej opcji',
    completeCourse: 'Zrealizuj kurs!',
    how: 'Jak wziąć udział?',
    knowhow: 'Przedstawiamy krok po kroku:',
    termsInfo:
      'Przed wysyłką formularza zgłoszeniowego koniecznie zapoznajcie się ze szczegółowymi zasadami konkursu opisanymi w Regulaminie oraz z informacjami RODO.',
    selectFile: 'Wybierz plik lub przeciągnij i upuść tutaj',
    typeOfFiles: 'JPG lub PDF, rozmiar pliku nie większy niż 15 MB',
    selectFileBtn: 'Wybierz plik',
    successTitle: 'Twoje zgłoszenie zostało wysłane. Dziękujemy!',
    secondStepError:
      'Liczba zaangażowanych uczniów nie może być większa od liczby uczniów na danym etapie edukacyjnym.',
    completedTitle: 'Nabór zgłoszeń do konkursu „Pracownia Kompetencji Cyfrowych” został zakończony.',
    sixSection: {
      downloadBtn: 'Pobierz (PDF)'
    }
  },
  oseServices: {
    title: 'Usługi OSE',
    readMoreBtnText: 'Czytaj więcej'
  },
  oseRegulatory: {
    tableTheadParam: 'Parametry'
  }
};

export default dictData;
